













































import { Observer } from 'mobx-vue'
import { Component, Inject, Prop, Vue } from 'vue-property-decorator'
import draggable from 'vuedraggable'
import { CreateCommunityViewModel } from '../../viewmodels/create-community-viewmodel'

@Observer
@Component({
  components: {
    draggable,
  },
})
export default class Classification extends Vue {
  @Prop({ default: false }) isContributorDao
  @Inject({}) vm!: CreateCommunityViewModel
  radioGroup = null
}
